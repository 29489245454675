import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import AwsS3 from "@uppy/aws-s3"
import { post } from "@rails/request.js"

// Configurations
const MAX_FILE_SIZE = 26214400; // 25mb in bytes
const MAX_NUMBER_OF_FILES = 10; // Limit to 10 files per product

export default class extends Controller {
  static targets = [ "input", "uppy", "fileList" ]
  static values = { 
    uppyid: String, 
    accept: Array,
    objectcount: Number,
    existingFiles: Array
  }

  // Connect the StimulusJS controller
  connect() {
    this.files = [] // Later we will be adding existingFiles to this if any
    this.isLoading = true // Set isLoading to true so we can load existing files first, if present.
    this.initUppy()
    this.initializeExistingImages() 
    this.objectcountValue = this.objectcountValue || 0; // Initialize the image count
  }

  // Initialize UppyJS
  initUppy() {
    this.uppyOptions();
    this.uppyListeners();
  }

  // All the options for UppyJS
  uppyOptions() {
    this.uppy = new Uppy({
      id: this.uppyidValue || 'uppy',
      inline: true,
      autoProceed: false, // IMPORTANT TO INITIATE WITH FALSE - SO WE CAN ADD FILES FIRST IF THERE'S EXISTING FILES ON THE MODEL
      restrictions: {
        maxFileSize: MAX_FILE_SIZE,
        maxNumberOfFiles: MAX_NUMBER_OF_FILES,
        allowedFileTypes: this.acceptValue || ['application/pdf'],
      }
    }).use(Dashboard, { 
      target: this.uppyTarget,
      replaceTargetContent: true,
      height: '140px',
      width: '100%',
      hideProgressAfterFinish: true,
      doneButtonHandler: null,
      showRemoveButtonAfterComplete: true,
      singleFileFullScreen: false,
      showLinkToFileUploadResult: true,
      note: 'Upload images for the product',
      inline: true,
      showProgressDetails: true,
      proudlyDisplayPoweredByUppy: false,
      hideCancelButton: this.hasExistingFilesValue,
    }).use(AwsS3, { 
      companionUrl: '/', // this calls the presigned url endpoint on the server at /s3/params
    })
  }

  uppyListeners() {
    this.uppy.on('file-added', this.fileAdded.bind(this))
    this.uppy.on('file-removed', this.fileRemoved.bind(this))
    this.uppy.on('upload-success', this.uploadSuccess.bind(this))
  }

  // Check for existing files and load each file into UppyJS
  // Once all files are loaded, set isLoading to false and set autoProceed to true
  async initializeExistingImages() {
    this.files = this.existingFilesValue
    let numLoading = this.existingFilesValue.length
    
    if (numLoading === 0) {
      this.setNotLoading()
    }

    for (const file of this.existingFilesValue) {
      this.uppy.addFile({
        id: file.file_id,
        name: file.name,
        type: file.type,
        uploadURL: file.remote_url,
        source: 'Server',
        data: new File(
          [file],
          file.name,
          { type: file.type }
        ),  // This is a hack to not have to load the actual file, just it's metadata. Uppy requires a File object or Blob to be passed in.
            // This is needed to show existing files in UppyJS Dashboard and being able to delete them
        meta: {
          file_id: file.public_id,
          key: file.file_id,
        }
      })

      numLoading--
      if (numLoading === 0) {
        this.setNotLoading()
      }
    }
  }

  setNotLoading() { 
    // This completes loading existing files and allows added files to automatically upload
    this.isLoading = false
    this.uppy.setOptions({ autoProceed: true })
  }

  // Gets called when you remove a file from UppyJS Dashboard
  fileRemoved(file, reason) {
    // Remove the file input element from the DOM so it won't get passed to the server

    const fileId = file.meta['key'].match(/^(?:cache\/)?(.+)/)[1].split('.')[0];
    let inputField = document.getElementById(fileId);

    if (inputField) {
      inputField.remove();
    } else {
      // If no input field with the id is found, look for an input field with data-uppy-value that matches the fileId
      // This is the field that will destroy the document from the procedure
      inputField = document.querySelector(`input[data-uppy-value="${fileId}"]`);
      if (inputField) {
        inputField.value = 'true';
      }
    }
  }

  // Get all files that were initially added, marked by existing file_id (set to public_id)
  getExistingUppyFiles() {
    return this.uppy.getFiles().filter(file => file.meta.hasOwnProperty('file_id'))
  }

  // Gets called whenever a file gets added, either by drag and drop or file selection OR when an existing file is loaded
  // If an existing file is loaded, it will skip uploading and set the fileState to uploadComplete = true
  fileAdded(file) {
    if (file.meta.hasOwnProperty('file_id')) {
      this.getExistingUppyFiles().forEach(file => {
        this.uppy.setFileState(file.id, {
          progress: {
            uploadComplete: true,
            uploadStarted: true
          },
          uploadURL: file.remote_url, // optional - for use with showLinkToFileUploadResult
        })
      })
    }
  }

  // Gets called when a file is successfully uploaded to S3
  // It will check the number of pages and create the actual document row on the server
  async uploadSuccess(file, response) {
    
    // get the number of pages of the uploaded file
    const getNumPagesAndDimensions = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const data = new Uint8Array(reader.result);
        const pdf = await getDocument(data).promise;
        const firstPage = await pdf.getPage(1);
        const viewport = firstPage.getViewport({ scale: 1 });
        const aspectRatio = viewport.width / viewport.height;
        resolve({
          numPages: pdf.numPages,
          width: viewport.width,
          height: viewport.height,
          aspectRatio: aspectRatio
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.data);
    });

    const { numPages, width, height, aspectRatio } = await getNumPagesAndDimensions;

    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      file_data: {
        id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          pages: numPages,
          width: width,
          height: height,
          aspect_ratio: aspectRatio,
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      }
    }

    const documentResponse = await post("/documents", {
      body: JSON.stringify(uploadedFileData),
      contentType: "application/json",
      responseKind: "json",
    });

    if (documentResponse.ok) {
      this.documentJSON = await documentResponse.json;
      this.addDocument(file);
      this.documentcountValue++;
      this.setFilePreview(file);
    } else {
      console.log('THERE WAS AN ERROR CREATING THE DOCUMENT ON THE SERVER');
    }
  }

  addDocument(file) {
    const fileId = file.meta['key'].match(/^cache\/(.+)/)[1].split('.')[0];
    const public_id = this.documentJSON['public_id'];

    const documentDiv = `
      <div class="document" data-uppy-target="documentInput">
        <input type="text" id="${fileId}" class="hidden" readonly="true" value="${public_id}" name="procedure[procedure_documents_attributes][${this.documentcountValue}][document_attributes][public_id]">
      </div>
    `;

    this.fileListTarget.insertAdjacentHTML('beforeend', documentDiv);
  }

  setFilePreview(file) {
    const thumbnailUrl = `${window.location.origin}${this.documentJSON['thumbnail_url']}`;
    this.uppy.setFileState(file.id, {
      preview: thumbnailUrl
    });
  }

  disconnect() {
    this.uppy.close()
  }
}