import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-form"
export default class extends Controller {
  static targets = [ "lineItem", "totalPrice" ]
  connect() {

  }

  updateTotal() {
    // Prevent update total price when total price is negative.
    if( this.#calculateTotal() >= 0 ) {
      this.totalPriceTarget.innerText = this.#calculateTotal()
    }
  }

  #calculateTotal() {
    let total = 0
    this.lineItemTargets.forEach((lineItem) => {
      total += parseFloat(lineItem.dataset.price) * parseFloat(lineItem.dataset.quantity)
    })
    return total.toFixed(2)
  }
}
