// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

// Make accessible for Electron and Mobile adapters
// window.Rails = Rails

// Import external modules
import "flowbite/dist/flowbite.turbo.js";
import "chartkick/chart.js"

// Stimulus controllers
import "./controllers"

import "trix"
import "@rails/actiontext"
import ahoy from "ahoy.js"

import { Current } from "current.js"
import { AnalyticsBrowser } from '@segment/analytics-next'
const analytics = AnalyticsBrowser.load({ writeKey: '46KffNLQqexP4jsvcPOkXG208Ipi4HrE' })
import "./service_worker/application"

document.addEventListener("turbo:visit", function(event) {
  // This event fires before visiting a new page
  sessionStorage.setItem("lastVisited", window.location.href);
});

// Track page impressions with Segment
document.addEventListener('turbo:load', () => {
    if (Current.environment == "production") {
      // Check if the user is logged in and if we haven't already identified them in this session
      if (Current.user && !sessionStorage.getItem('analyticsIdentified')) {
        analytics.identify(Current.user.id);
        // Mark as identified in this session
        sessionStorage.setItem('analyticsIdentified', 'true');
      }
      analytics.page();
    }
});

// Custom Turbo Confirmation Modal
Turbo.setConfirmMethod((message, element) => {
    let dialog = document.getElementById("turbo-confirm")
    dialog.querySelector("p").textContent = message
    dialog.showModal()

    return new Promise((resolve, reject) => {
        dialog.addEventListener("close", () => {
            resolve(dialog.returnValue == "confirm")
        }, { once: true })
    })
})

