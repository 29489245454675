import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select';

export default class extends Controller {
    static values = { selected: String, allowCreate: Boolean }

    connect() {
        
        var config = {
            create: this.allowCreateValue || false,  // Allow creation of new items
            persist: false, // Do not keep the created option after deselecting
            createOnBlur: true, // Create the item when the input loses focus
            maxOptions: null, // The max number of options to display in the dropdown. Set maxOptions to null for an unlimited number of options.
            createFilter: (input) => {
                return input.length > 0; // Only allow creation if the input is not empty
            }
        }
       
        let select_field = new TomSelect(this.element, config)

    }
}
