import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product--check-form"
export default class extends Controller {
  static targets = [ "info" ]
  static values = {
    queryFrame: String
  }

  connect() {
  }

  submit(e) {
    if ( this.infoTarget.value?.startsWith("https:/") ) {
      this.element.dataset.turboFrame = "modal"
    } else if ( this.queryFrameValue ) {
      this.element.dataset.turboFrame = this.queryFrameValue
    }

    this.element.requestSubmit()

    setTimeout(() => delete this.element.dataset.turboFrame )
  }
}
