import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-form-line-item"
export default class extends Controller {
  static targets = [ "quantityField" ]

  connect() { }

  updateQuantity() {
    this.element.dataset.quantity = this.quantityFieldTarget.value
    this.#triggerQuantityChangedEvent()
  }

  incrementQuantity() {
    this.quantityFieldTarget.value = parseInt(this.quantityFieldTarget.value) + 1
    this.element.dataset.quantity = this.quantityFieldTarget.value
    this.#triggerQuantityChangedEvent()
  }

  decrementQuantity() {
    if (parseInt(this.quantityFieldTarget.value) > 1) {
      this.quantityFieldTarget.value = parseInt(this.quantityFieldTarget.value) - 1
      this.element.dataset.quantity = this.quantityFieldTarget.value
      this.#triggerQuantityChangedEvent()
    }
  }

  #triggerQuantityChangedEvent() {
    this.element.dispatchEvent(new CustomEvent("quantity-changed"))
  }
}
